import React, { useState } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { useHistory, useLocation } from 'react-router-dom';
import openNotification from '../../../../Shared/utils/open_notification';
import InputMask from 'react-input-mask';
import Spacer from '../../../../Shared/Components/Spacer';
import './styles.css'

function HomePagePart1() {
  const history = useHistory()
  const [celular,setCelular] = useState('');

  function useQuery(){
    const location = useLocation();
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const cod_indicacao = query.get('utm_content');
  
  function handleContinuar(e) {
    e.preventDefault();

    
    if(cod_indicacao !== null) {
      localStorage.setItem('utm_content', cod_indicacao);
    }
    if(celular === '' || celular.endsWith('_')) {
      openNotification('Número inválido','Digite seu número de whatsapp para continuar');
    }
    else {
      localStorage.setItem('whatsapp',celular);
      history.push('/cadastro');
    }
  }

  return (
    <>
    <div className="geral-container-part1">
      <div className="left-container-part1">
        <div className="text-container-part1">
            <label className="top-text-frotabank-part1">
              Frotabank é o<br/> seu banco na estrada
            </label>
            <div>
              <p className="bottom-text-frotabank-part1">
                Agora você pode fazer saques e<br/> depósitos no posto, e receber seu<br/> troco direto na sua conta.
              </p>
            </div>
          </div>
        </div>
        <div className="right-container-part1">
        <div className="container-whatsapp">
          <div className="content-container">
            <div className="text-container-whatsapp">
              <p> Peça sua conta digital com cartão de débito e agência no posto.</p>
            </div>
            <Spacer margin="5%"/>
            <InputMask
              mask="(99) 9 9999-9999"
              className="input-whatsapp"
              placeholder='Digite seu número do whatsapp'
              onChange={(e) => setCelular(e.target.value)}
            />
            <Spacer />
            <button className="button-primary" onClick={handleContinuar}>Continuar <HiOutlineArrowNarrowRight className="arrow-avancar" /></button>
          </div>
          </div>
        </div>
      </div>
      </>
  )
}
export default HomePagePart1
