import React from 'react';
import { Modal, Button } from 'antd';

const ModalFrotabank = ({title,handleOk,isModalVisible,handleCancel}) => {

    return (
      <>
        <Modal 
            title={title} 
            visible={isModalVisible} 
            onOk={handleOk}
            style={{ top: 20 }}
            onCancel={handleCancel}
            width="90%"
            footer={[
                <Button style={{backgroundColor: '#4AB8A8',color: 'white' }} key="Ok" onClick={handleOk}>Ok</Button>
            ]}
            >
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <iframe style={{height: '600px', width: "100%"}} title={title} src="https://www.frotabank.com.br/politica-de-privacidade/"></iframe>
            </div>

        </Modal>
      </>
    );
}

export default ModalFrotabank;