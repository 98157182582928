import React, { useEffect, useState } from 'react'
import Celular from '../../../../assets/celular.png'
import Spacer from '../../../../Shared/Components/Spacer';
import getDeviceType from '../../../../Shared/utils/getDeviceType';
import ContatoContainer from '../Contato/Components/ContatoContainer';
import './styles.css';

function HomePagePart4() {
  const [device, setDevice] = useState('');

  useEffect(() => {
    setDevice(getDeviceType());
  }, []);

  return (
    <div className="geral-container-part4">
      <div className="left-container-part4">
        <div className="left-text-container-part4">
         <label>Aplicativo completo</label>
          <Spacer />
          <label>para você fazer <br className="hide-rps"/>transferências e <br/> pagar seus boletos</label>
        </div>
      </div>
    <div className="right-container-part4">
      <img src={Celular} alt="celular"/>
    </div>
    {device === 'mobile' &&
    <ContatoContainer />
    }
  </div>
  )
}
export default HomePagePart4
