import React from 'react';
import './styles.css';

function ContatoContainer() {
  return (
    <div className="contato-container-mobile">
    <div className="atendimento-contato-mobile">
      <label>Atendimento</label>
    </div>
    <div className="numero-contato-mobile">
      <label>0800 153 7000</label>
    </div>
    <div className="email-contato-mobile">
      <label>ajuda@frotabank.com.br</label>
    </div>
  </div>
  );
}

export default ContatoContainer;