import React, { useEffect, useState } from 'react'
import gplayimg from '@/assets/googleplay.png'
import appleStore from '@/assets/apple-store.png'
import instagram from '@/assets/instagram-icon.svg'
import linkedin from '@/assets/linkedin-icon.svg'
import youtube from '@/assets/youtube-icon.svg'
import facebook from '@/assets/facebook-icon.svg'
import Barra from '@/Shared/Barra'
import getDeviceType from '@/Shared/utils/getDeviceType'

import './styles.css'

function Contato() {
  const [device, setDevice] = useState()

  useEffect(() => {
    setDevice(getDeviceType())
  }, [])

  return (
    <>
      {device === 'desktop' ? (
        <div className="geral-container-part5">
          <div className="barras-container">
            <div className="content1-container">
              <div className="barra2-container">
                <Barra />
                <Barra />
              </div>
              <div className="atendimento-container2">
                <label className="atendimento2">Atendimento</label>
              </div>
              <div className="numero-contato-container2">
                <label className="numero-contato">0800 153 7000</label>
              </div>
              <div className="email-contato-container2">
                <label>ajuda@frotabank.com.br</label>
              </div>

              <div className="redes-sociais-container">
                <a href="https://www.facebook.com/frotabank">
                  <img src={facebook} alt="facebook" />
                </a>
                <a href="https://www.linkedin.com/company/frota-bank">
                  <img src={linkedin} alt="linkedin" />
                </a>
                <a href="https://www.instagram.com/frotabank/">
                  <img src={instagram} alt="instagram" />
                </a>
                <a href="https://www.youtube.com/channel/UCV10hLEfxn8JjPAbEqX3YQg">
                  <img src={youtube} alt="youtube" />
                </a>
              </div>
            </div>

            <div className="content2-container">
              <div className="barra2-container">
                <Barra />
                <Barra />
              </div>
              <div className="links">
                <div className="left-links">
                  <a href="/">Página inicial</a>
                  <a href="https://www.frotabank.com.br/sobre-nos/">
                    Sobre nós
                  </a>
                  <a href="https://www.frotabank.com.br/app/">Aplicativo</a>
                  <a href="https://play.google.com/store/apps/details?id=br.com.frotabank.app">
                    {}
                  </a>
                  <img src={gplayimg} alt="google play" />
                </div>

                <div className="right-links">
                  <a href="https://www.frotabank.com.br/perguntas-frequentes/">
                    Perguntas frequentes
                  </a>
                  <a href="https://www.frotabank.com.br/tarifas/">Tarifas</a>
                  <a href="https://www.frotabank.com.br/politicas-de-privacidade/">
                    Políticas de privacidade
                  </a>
                  <a href="https://apps.apple.com/br/app/frotabank/id1572331710">
                    {}
                  </a>
                  <img src={appleStore} alt="apple store" />
                </div>
              </div>
            </div>

            <div className="mapa-container2">
              <div className="barra2-container">
                <Barra />
                <Barra />
              </div>
              <a
                href="https://maps.google.com/maps?ll=-16.696016,-49.22228&z=10&t=m&hl=pt-BR&gl=US&mapclient=embed&cid=16055670041699194498"
                className="mapa"
              >
                {' '}
              </a>
              <div className="endereco-frotabank2">
                Av. Olinda, N 960, Edfício Trade Tower. <br /> Park Lozandes,
                Goiânia-GO
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="geral-container-mobile">
          <div className="links-redes-container">
            <div className="content1-mobile">
              <div className="barra-container-mobile">
                <Barra />
              </div>
              <div className="links-mobile">
                <a href="/">Página inicial</a>
                <a href="https://www.frotabank.com.br/sobre-nos/">Sobre nós</a>
                <a href="https://www.frotabank.com.br/app/">Aplicativo</a>
                <a href="https://www.frotabank.com.br/perguntas-frequentes/">
                  Perguntas frequentes
                </a>
                <a href="https://www.frotabank.com.br/tarifas/">Tarifas</a>
                <a href="https://www.frotabank.com.br/politicas-de-privacidade/">
                  Políticas de privacidade
                </a>
                <a href="https://apps.apple.com/br/app/frotabank/id1572331710">
                  {}
                </a>
              </div>
              <div className="barra-container-mobile">
                <Barra />
              </div>
            </div>

            <div className="content2-mobile">
              <div className="barra-container-mobile2">
                <Barra />
              </div>
              <div className="redes-sociais-container-mobile">
                <a href="https://www.facebook.com/frotabank">
                  <img src={facebook} alt="facebook" />
                </a>
                <a href="https://www.linkedin.com/company/frota-bank">
                  <img src={linkedin} alt="linkedin" />
                </a>
                <a href="https://www.instagram.com/frotabank/">
                  <img src={instagram} alt="instagram" />
                </a>
                <a href="https://www.youtube.com/channel/UCV10hLEfxn8JjPAbEqX3YQg">
                  <img src={youtube} alt="youtube" />
                </a>
              </div>
              <div className="barra-container-mobile2">
                <Barra />
              </div>
            </div>
          </div>
          <div className="mapa-container-mobile">
            <div className="endereco-container-mobile">
              Av. Olinda, N 960, Edfício Trade Tower. <br /> Park Lozandes,
              Goiânia-GO
            </div>
            <a
              href="https://maps.google.com/maps?ll=-16.696016,-49.22228&z=10&t=m&hl=pt-BR&gl=US&mapclient=embed&cid=16055670041699194498"
              className="mapa"
            >
              {}
            </a>
          </div>
        </div>
      )}
    </>
  )
}

export default Contato
