import { React } from 'react'
import HomePagePart1 from './Components/HomePagePart1/index.js'
import HomePagePart2 from './Components/HomePagePart2/index.js'
import HomePagePart3 from './Components/HomePagePart3/index.js'
import HomePagePart4 from './Components/HomePagePart4'
import Contato from './Components/Contato'
import FrotaBankNavbar from '../../Shared/Components/FrotaBankNavBar'

import './styles.css'

function Home() {
  return (
    <>
      <FrotaBankNavbar />
      <HomePagePart1 />
      <HomePagePart2 />
      <HomePagePart3 />
      <HomePagePart4 />
      <Contato />
      <footer>
        Rede Frota Solutions Ltda - Todos os direitos reservados CNPJ:
        24.478.438/0001-48
      </footer>
    </>
  )
}
export default Home
