import React from 'react'
import './styles.css'

function HomePagePart2() {
  return (
    <div className="geral-container-part2">
      <div className="texto-video-container">
        <div className="text-container">
          <p className="top-text-part2">
            Pensado para o caminhoneiro,
            <br />
          </p>
          <p className="top-text2-part2"> feito para todos</p>
          <iframe
            className="video"
            width="747"
            height="420"
            src="https://www.youtube.com/embed/HrtYX2T4Jsg?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&autoplay=0&hd=1&wmode=transparent"
            title="YouTube video player"
            frameBorder="0"
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </div>
    </div>
  )
}
export default HomePagePart2
