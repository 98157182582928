import React from 'react'
import Celulares from '../../../../assets/celulares.png'
import CartaoFrotaBank from '../../../../assets/cartaofrotabank.png'
import './styles.css'

function LeftSide() {
  return (
       <div className="left-container">
         <div className="title-top">
            <label>Conta digital com cartão de <br/> debito <span> e agência no posto</span></label>
          </div>
            <div className="hero">
              <img src={Celulares} alt="celulares" className="celulares"/>
              <img src={CartaoFrotaBank} alt="cartao frotabank" className="cartaoFrota"/>
          </div>
      </div>
  )
}

export default LeftSide
