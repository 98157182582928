import React from 'react'
import CartaoFrotaBank from '../../../../assets/cartaofrotabank.png'
import './styles.css'
// import { Container, Title, SubTitle }  from '../../../../global';

function HomePagePart3() {
  return (
    <>
    <div className="geral-container-part3">
      <div className="left-container-part3">
        <div className="text-left-container-part3">
            <p>Cartão de debito <br/> bandeira Visa</p>
            <p>para você usar em qualquer <br/>lugar e fazer saques nos <br/> bancos 24Horas</p>
          </div>
        </div>
        <div className="right-container-part3">
          <img src={CartaoFrotaBank} alt="cartao frota bank"/>
      </div>
    </div>
    </>
  )
}
export default HomePagePart3
