import React from 'react';
import barraimg from '@/assets/barra.png';
import './styles.css';

function Barra() {
  return( 
    <img src={barraimg} alt="barra" />
  );
}

export default Barra;