import React from 'react'
import './styles.css'
import FrotaBankNavBar from '../../Shared/Components/FrotaBankNavBar/index'
import LeftSide from './Components/LeftSide/index'
import RightSide from './Components/RightSide/index'

function Cadastro() {
  return (
  <>
     <FrotaBankNavBar />
     <div className="geral-container-presentation">
      <LeftSide />

      <RightSide />
    </div>
  </>
  )
}

export default Cadastro
