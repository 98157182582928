import React, { useEffect, useState } from 'react'
import FrotaBankLogo from '@/assets/frotabank-logo.svg'
import { Link } from 'react-router-dom'
import { AiFillStar } from 'react-icons/ai';
import Barra from '../../Barra';
import './styles.css'
import getDeviceType from '../../utils/getDeviceType';


function FrotaBankNavBar() {

  function toggleMenu () {
    let showOn = true;
    //nao ter barra de rolagem quando o menu tiver aberto
    // document.body.style.overflow = showOn ? 'hidden' : 'initial'; 
   
    var menuSection = document.querySelector('.menu-section');
        
    menuSection.classList.toggle('on', showOn);
    showOn = !showOn;
      
  };

  window.addEventListener('click', function(e) {
    var menuSection = document.querySelector('.menu-section');
    if(!menuSection.contains(e.target)) {
      // toggleMenu();
      menuSection.classList.remove('on');
    }
  });

  // var menu = document.querySelector('.menu-section');
  // menu.addEventListener('focusout',()=> toggleMenu);

  const [deviceType, setDeviceType] = useState('');
  useEffect(() => {
    setDeviceType(getDeviceType());
  }, []);

  // console.log(deviceType);
  return (
  <header>
      <Link to="/">
        <img className="logo" src={FrotaBankLogo} alt="logo frotabank" />
      </Link> 
    
      <div className="menu-section">
        <div className="menu-toggle" onClick={toggleMenu}>
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>

          <Barra />
        <nav className="nav-links">
          <ul>
            {
              deviceType === 'mobile' ? 
              <li>
                <div className="link-pag-inicial">
                  <Link id="pagInicial" to="/">Página inicial</Link>
                  <AiFillStar id="star-icon"/>
                </div>
              </li>
              :
              <li>
                <Link to="/">Página inicial</Link>
              </li>
            }
            <li>
              <a href="https://www.frotabank.com.br/sobre-nos/">Sobre nós</a>
            </li>
            {deviceType === 'mobile' ? 
            <li>
              <a href="https://www.frotabank.com.br/perguntas-frequentes/">Perguntas frequentes</a>
            </li>
            :
            <li>
              <a href="https://www.frotabank.com.br/aplicativo/">Aplicativo</a>
            </li>
            }
            <li>
              <a href="https://www.frotabank.com.br/tarifas/">Tarifas</a>
            </li>
            {deviceType === 'mobile' ? 
            <li>
              <a href="https://www.frotabank.com.br/politicas-de-privacidade/">Políticas de privacidade</a>
            </li>
            :
            <li>
              <a href="https://www.frotabank.com.br/blog/">Blog</a>
            </li>
            }
            
          </ul>
        </nav>
      </div>
    </header>
  )
}
export default FrotaBankNavBar
