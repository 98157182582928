import { notification } from 'antd';

const OpenNotification = (message,description) => {
    
    const key = Date.now;
    
    notification.open({
      message: message ?? '',
      description: description ?? '',
      key,
      onClick: () => {
        notification.close(key);
      },
    });
  };

  export default OpenNotification;
